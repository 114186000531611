.navbar-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .navbar-container {
    justify-content: center;
    width: 75%;
  }
}

.navbar {
  display: flex;
  justify-content: space-around;
  width: 286px;
  font-family: "Camphor", Open Sans, Segoe UI, sans-serif;
  font-size: 1.2em;
  margin-top: 40px;
  margin-bottom: 100px;
  gap: 50px;
}
@media only screen and (max-width: 767px) {
  .navbar-container {
    font-size: 1em;
  }
}

.navbar a {
  position: relative;
  text-decoration: none;
  color: purple;
  font-weight: bold;
}
.navbar a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 0;
  height: 5px;
  background-color: blue;
  content: "";
  transition: width 0.15s;
}

.navbar a:hover {
  cursor: pointer;
  color: #6673e5;
}
.navbar a:hover:after {
  width: 100%;
}
